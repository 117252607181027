import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      menu:['about', 'work'],
      servicesNav:this.props.servicesNav
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }))
  }

  render() {
    const servicesNav = this.props.servicesNav
    return(
      <nav className="navbar is-transparent">
        <div className="container">
          <div className="navbar-brand">
            <Link  to="/">
              <img src="/img/logo-white.png" alt="logo" width="85" height="85"/>
            </Link>
            <span onClick={this.handleClick} className={this.state.isActive ? "navbar-burger burger is-active" : "navbar-burger burger"} data-target="navbarMenuHeroA">
              <span/>
              <span/>
              <span/>
            </span>
          </div>
          <div id="navbarMenuHeroA" className={this.state.isActive ? "navbar-menu is-active" : "navbar-menu"}>
            <div className="navbar-end">
              <div className="navbar-item is-size-5 is-capitalized has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless"><Link to="/services/">industries</Link></div>
                <div className="navbar-dropdown is-boxed is-size-5">
                  <a className="navbar-item is-size-5 is-capitalized" href='https://luxury.reddigitalchina.com/' target="_blank">Luxury, Interior & Property</a>
                  <a className="navbar-item is-size-5 is-capitalized" href='https://students.reddigitalchina.com/' target="_blank">Student accommodation</a>
                  <a className="navbar-item is-size-5 is-capitalized" href='https://beauty.reddigitalchina.com/' target="_blank">Beauty</a>
                  <a className="navbar-item is-size-5 is-capitalized" href='https://b2b.reddigitalchina.com/' target="_blank">B2B</a>
                  <a className="navbar-item is-size-5 is-capitalized" href='https://hospitality.reddigitalchina.com/' target="_blank">Hospitality</a>
                  <a className="navbar-item is-size-5 is-capitalized" href='https://intelligence.reddigitalchina.com/' target="_blank">Metaverse</a>
                </div>
              </div>
              <div className="navbar-item is-size-5 is-capitalized has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless"><Link to="/services/">services</Link></div>
                <div className="navbar-dropdown is-boxed is-size-5">
                  {
                    servicesNav ?
                    servicesNav.map(({node},index)=>{
                      return(
                        <Link className="navbar-item" key={index} to={`/services/${node.slug}/`}>{node.title}</Link>
                      )
                    }) : null
                  }
                </div>
              </div>
              {
                this.state.menu.map((item) => {
                  return(
                    <Link className="navbar-item is-size-5 is-capitalized" to={`/${item}/`} key={item}>{item}</Link>
                  )
                })
              }
              <a className="navbar-item is-size-5 is-capitalized" href='/#contact'>contact</a>
              <a className="navbar-item is-size-5 is-capitalized" href='https://media.reddigitalchina.com/' target="_blank">media</a>
              <div className="navbar-item is-size-5 is-capitalized has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">resources</div>
                <div className="navbar-dropdown is-boxed is-size-5">
                  <Link className="navbar-item" to="/blog/">blog</Link>
                  <Link className="navbar-item" to="/academy/">academy</Link>
                  <a className="navbar-item" href="https://training.reddigitalchina.com" target='_blank'>China E-commerce Training</a>
                  <a className="navbar-item" href="https://webinar.reddigitalchina.com/" target='_blank'>webinar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Nav 
